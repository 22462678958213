import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Contact } from "./Components/Page/Contact/Contact";
import { Support } from "./Components/Page/Support/Support";
import { Home } from "./Components/Page/Home/Home";
import { Header } from "./Components/Layout/Header/Header";

function App() {
  return (
    <>
      <BrowserRouter>
      <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Support" element={<Support />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
