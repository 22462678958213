import React from 'react'
import { Link } from "react-router-dom"

export const Header = () => {
  return (
    <div>
        <ul>
            <Link to={'/'}><li>Home</li></Link>
            <Link to={'/support'}><li>support</li></Link>
            <Link to={'/contact'}><li>contact</li></Link>
        </ul>
    </div>
  )
}
